import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr'; // https://github.com/scttcper/ngx-toastr

@Injectable({
    providedIn: 'root'
})

export class AlertMessageService {

    private messages = {
        'successTitle': {
            'es': 'Éxito!',
            'en': 'Success!'
        },
        'invalidCode': {
            'es': 'Código de mensaje desconocido!',
            'en': 'Unknow message code'
        },
        'errorTitle': {
            'es': 'Error!',
            'en': 'Error!'
        },
        'warning': {
            'es': 'Cuidado!',
            'en': 'Warning!'
        },
        'CE': {
            'es': 'Error en la conexión',
            'en': 'Connection error'
        },
        'IVC': {
            'es': 'Credenciales Inválidas',
            'en': 'Invalid Credentials'
        },
        'ANA': {
            'es': 'Cuenta no activada',
            'en': 'Account not activated'
        },
        'EUC': {
            'es': 'Error actualizando transportadora',
            'en': 'Error updating carrier'
        },
        'RSC': {
            'es': 'Usuario registrado con éxito, revisa tu correo para validar la cuenta',
            'en': 'User registered succesfully, check you email to validate your account'
        },
        'EXM': {
            'es': 'Email existente',
            'en': 'Existent email'
        },
        'EDC': {
            'es': 'Documento existente',
            'en': 'Existent document'
        },
        'INF': {
            'es': 'Campos inválidos',
            'en': 'Invalid fields'
        },
        'NPB': {
            'es': 'No hay parámetros en el cuerpo de la solicitud',
            'en': 'No params body'
        },
        'IVU': {
            'es': 'Usuario inválido',
            'en': 'Invalid user'
        },
        'IVP': {
            'es': 'Pre-alerta no encontrada',
            'en': 'Pre-Alert not found'
        },
        'IVW': {
            'es': 'Peso inválido',
            'en': 'Invalid weight'
        },
        'SRP': {
            'es': 'Pre alerta registrada éxitosamente!',
            'en': 'Pre alert was successfully registered in the warehouse!'
        },
        'EUR': {
            'es': 'Solo se puede subir un archivo!',
            'en': 'You can only upload a file!'
        },
        'SFE': {
            'es': 'Prealerta encontrada con éxito!',
            'en': 'Prealert found successfully!'
        },
        'SWR': {
            'es': 'Prealerta registrada con éxito!',
            'en': 'Prealert registered successfully!'
        },
        'EXP': {
            'es': 'Número de rastreo existente!',
            'en': 'Existing tracking number!'
        },
        'SCR': {
            'es': 'Cambios en el paquete aprobados con éxito!',
            'en': 'Changes in the package approved successfully!'
        },
        'LCS': {
            'es': 'Nueva dirección registrada con éxito!',
            'en': 'New location registered successfully!'
        },
        'SCQ': {
            'es': 'Solicitud de envío con éxito!',
            'en': 'Request shipping successfully!'
        },
        'ISTP': {
            'es': 'Seleccione un envío!',
            'en': 'Select a Shipping!'
        },
        'SFP': {
            'es': 'Pago contra entrega registrado con éxito!',
            'en': 'COD payment Registered successfully!'
        },
        'SEP': {
            'es': 'Pago realizado con éxito!',
            'en': 'Payment Registered successfully!'
        },
        'SDP': {
            'es': 'Envío despachado con éxito!',
            'en': 'Shipment successfully dispatched!'
        },
        'SCH': {
            'es': 'Solicitud consolidada con éxito!',
            'en': 'Request successfully consolidated!'
        },
        'EEP': {
            'es': 'Error realizando el pago!',
            'en': 'Error registering payment!'
        },
        'FCN': {
            'es': 'Contenedor No Encontrado!',
            'en': 'Container Not Found!'
        },
        'RGCN': {
            'es': 'Contenedor Ya Registardo En El País!',
            'en': 'Container Registered!'
        },
        'RSCT': {
            'es': 'Contenedor Registrado Con Éxito!',
            'en': 'Container successfully Registered!'
        },
        'UPBC': {
            'es': 'Caja(s) Retirada(s) De Aduana Con Éxito!',
            'en': 'Box(es) Claimed From Customs!'
        },
        'ENBC': {
            'es': 'La(s) Caja(s) no está(n) en Aduana!',
            'en': 'Box(es) not in Customs!'
        },
        'SDCC': {
            'es': 'Paquete despachado a Ciudad Destino!',
            'en': 'Package dispatched to Destination City!'
        },
        'INP': {
            'es': 'Permisos Inválidos!',
            'en': 'Invalid Permissions!'
        },
        'CSH': {
            'es': 'Tienda Creada Con Éxito!',
            'en': 'Shop Successfully Created!'
        },
        'USP': {
            'es': 'Tienda Editada con Éxito!',
            'en': 'Shop Successfully Updated!'
        },
        'NSE': {
            'es': 'Tienda Existente!',
            'en': 'Shop Already Exists!'
        },
        'SHN': {
            'es': 'Tienda No Encontrada!',
            'en': 'Shop Not Found!'
        },
        'SDL': {
            'es': 'Tienda Borrada!',
            'en': 'Shop Deleted!'
        },
        'CRS': {
            'es': 'Transportadora Creada con Éxito!',
            'en': 'Carrier Succesfully Created!'
        },
        'SEC': {
            'es': 'Transportadora Editada!',
            'en': 'Carrier Edited'
        },
        'CNE': {
            'es': 'Transportadora Existente!',
            'en': 'Carrier Already Exists!'
        },
        'CNF': {
            'es': 'Transportadora No Encontrada!',
            'en': 'Carrier Not Found!'
        },
        'CDL': {
            'es': 'Transportadora Desactivada!',
            'en': 'Carrier Deactivated!'
        },
        'OPR': {
            'es': 'Operario Creado!',
            'en': 'Operator Created!'
        },
        'OPF': {
            'es': 'Error Al Crear Operario!',
            'en': 'Error Creating Operator!'
        },
        'OPE': {
            'es': 'Operario Editado!',
            'en': 'Operator Edited!'
        },
        'EEO': {
            'es': 'Error Editando Operario!',
            'en': 'Error Editing Operator!'
        },
        'SECO': {
            'es': 'Costos Actualizados!',
            'en': 'Costs Updated!'
        },
        'ERSR': {
            'es': 'Error del Servidor!',
            'en': 'Server Error!'
        },
        'SEUS': {
            'es': 'Servicio actualizado!',
            'en': 'Service updated!'
        },
        'ENE': {
            'es': 'Servicio existente!',
            'en': 'Service already exists!'
        },
        'SCSR': {
            'es': 'Servicio creado!',
            'en': 'Service created!'
        },
        'UDL': {
            'es': 'Operario eliminado!',
            'en': 'Operator deleted!'
        },
        'SLAV': {
            'es': 'Foto Cambiada Con Éxito!',
            'en': 'Photo Succesfully Updated!'
        },
        'NFI': {
            'es': 'Error Cambiando La Foto!',
            'en': 'Error Updating The Photo!'
        },
        'EFT': {
            'es': 'Error! La Foto debe tener un tamaño menor a 5Mb',
            'en': 'Error! The image must be smaller than 5Mb'
        },
        'EFTGIF': {
            'es': 'Error! El GIF debe tener un tamaño menor a 3,5Mb',
            'en': 'Error! The GIF must be smaller than 3,5Mb'
        },
        'TPF': {
            'es': 'Error! La Foto debe tener un formato JPG o PNG',
            'en': 'Error! The image must be JPG or PNG'
        },
        'EFTM': {
            'es': 'Error! El archivo debe tener un tamaño menor a 5Mb',
            'en': 'Error! The file must be smaller than 5Mb'
        },
        'EFF': {
            'es': 'Error! El archivo debe tener un formato XLSX o CSV',
            'en': 'Error! The file must be XLSX or CSV'
        },
        'DLAC': {
            'es': 'Servicio Activado!',
            'en': 'Service Activated!'
        },
        'DLSV': {
            'es': 'Servicio Desactivado!',
            'en': 'Service Deactivated!'
        },
        'EQR': {
            'es': 'Error! La cantidad de archivos no es soportada!',
            'en': 'Error! Quantity of files not soported!'
        },
        'ACTV': {
            'es': 'Transportadora activada!',
            'en': 'Carrier Activated!'
        },
        'PRGT': {
            'es': 'Pre-alerta ya registrada',
            'en': 'Prealert Registered!'
        },
        'USPK': {
            'es': 'Paquete asignado con éxito!',
            'en': 'Package assigned succesfully!'
        },
        'ESCC': {
            'es': 'Información Editada!',
            'en': 'Information Updated!'
        },
        'VLWP': {
            'es': 'Cuenta Validada!',
            'en': 'Account Validated!'
        },
        'NUFS': {
            'es': 'Usuario no Encontrado!',
            'en': 'User not Found!'
        },
        'ECU': {
            'es': 'Error al crear usuario',
            'en': 'Error creating user'
        },
        'SESR': {
            'es': 'Servicio Editado',
            'en': 'Service Updated'
        },
        'EGDT': {
            'es': 'Error obtiendo posición arancelaria',
            'en': 'Error Getting Duty Tax'
        },
        'SRDT': {
            'es': 'Éxito registrando posiciones arancelarias',
            'en': 'Success registering Duty Tax'
        },
        'ERDT': {
            'es': 'Error registrando posiciones arancelarias',
            'en': 'Error registering Duty Tax'
        },
        'SUDT': {
            'es': 'Éxito actualizando posición arancelaria',
            'en': 'Success Updating Duty Tax'
        },
        'EUDT': {
            'es': 'Error actualizando posición arancelaria',
            'en': 'Error Updating Duty Tax'
        },
        'DDTA': {
            'es': 'Posición arancelaria por defecto aplicada!',
            'en': 'Default Duty Tax Applied!'
        },
        'EDPE': {
            'es': 'Error el precio declarado no puede superar los 2000USD',
            'en': 'The total declared price can\'t exceed 2000USD'
        },
        'EDWS': {
            'es': 'Error el peso total no puede superar las 110lb',
            'en': 'The total weight of the shipping can\'t exceed 110lb'
        },
        'SRSH': {
            'es': 'Solicitud Rechazada!',
            'en': 'Request Rejected!'
        },
        'ERSH': {
            'es': 'Error al Rechazar Solicitud',
            'en': 'Error rejecting Request'
        },
        'SECT': {
            'es': 'Contenidos editados',
            'en': 'Contents edited'
        },
        'EECT': {
            'es': 'Error al editar contenidos',
            'en': 'Error editing contents'
        },
        'ECMSG': {
            'es': 'Mensaje enviado',
            'en': 'Message sended'
        },
        'SUU': {
            'es': 'Usuario actualizado',
            'en': 'User updated'
        },
        'EPCOD': {
            'es': 'Error en porcentaje COD',
            'en': 'Error in COD percentage'
        },
        'EPCC': {
            'es': 'Error en porcentaje Tarjeta de crédito',
            'en': 'Error in Credit Card percentage'
        },
        'EPPP': {
            'es': 'Error en porcentaje PayPal',
            'en': 'Error in PayPal percentage'
        },
        'EMIPI': {
            'es': 'Error asignando porcentajes de ganancias en seguro',
            'en': 'Error assigning insurance percentage share'
        },
        'EMAPI': {
            'es': 'Error asignando porcentajes de ganancias en seguro',
            'en': 'Error assigning insurance percentage share'
        },
        'EMIPT': {
            'es': 'Error asignando porcentajes de ganancias en impuestos',
            'en': 'Error assigning tax percentage share'
        },
        'EMAPT': {
            'es': 'Error asignando porcentajes de ganancias en impuestos',
            'en': 'Error assigning tax percentage share'
        },
        'SSFL': {
            'es': 'Dirección favorita cambiada',
            'en': 'Favorite Address changed'
        },
        'ESFL': {
            'es': 'Error cambiando dirección favorita',
            'en': 'Error changing favorite location'
        },
        'ICA': {
            'es': 'Transportadora no encontrada',
            'en': 'Carrier not found'
        },
        'SRFL': {
            'es': 'Dirección eliminada',
            'en': 'Address Deleted'
        },
        'ERFL': {
            'es': 'Error eliminando la dirección',
            'en': 'Error deleting the address'
        },
        'NCY': {
            'es': 'Ciudad no encontrada',
            'en': 'City not found'
        },
        'SPPW': {
            'es': 'Revisa tu correo para reestablecer la contraseña',
            'en': 'Check your email to reset the password'
        },
        'SCHPSS': {
            'es': 'Contraseña cambiada con éxito',
            'en': 'Password changed sucessfully'
        },
        'EGPUPRQ': {
            'es': 'Error obteniendo las solicitudes de recogida',
            'en': 'Error getting pickup requests'
        },
        'ECPUPRQ': {
            'es': 'Error al solicitar recogida',
            'en': 'Error creating pickup request'
        },
        'SCPUPRQ': {
            'es': 'Solicitud de recogida registrada',
            'en': 'Pickup requested'
        },
        'GNF': {
            'es': 'Guía no encontrada',
            'en': 'Waybill not found'
        },
        'EXTR': {
            'es': 'El tracking ya está registrado',
            'en': 'Tracking already registered'
        },
        'SWRP': {
            'es': 'Paquete registrado en Bodega',
            'en': 'Package registered in Warehouse'
        },
        'EXMWR': {
            'es': 'El correo se encuentra registrado, favor buscar el WR',
            'en': 'Email already in use, please search for WR'
        },
        'ERSTH': {
            'es': 'Error al crear almacén',
            'en': 'Error creating storehouse'
        },
        'SRSTH': {
            'es': 'Almacén creado con éxito',
            'en': 'Storehouse created successfully'
        },
        'ERSTT': {
            'es': 'Error al crear tipo de almacenamiento',
            'en': 'Error creating storage type'
        },
        'SRSTT': {
            'es': 'Tipo de almacenamiento creado',
            'en': 'Storage type created successfully'
        },
        'ELSTH': {
            'es': 'Error al listar almacenes',
            'en': 'Error listing storehouses'
        },
        'ELSTT': {
            'es': 'Error listando tipo de almacenamiento',
            'en': 'Error listing storage type'
        },
        'EEST': {
            'es': 'Tipo de almacenamiento existente',
            'en': 'Existing storage type'
        },
        'EESH': {
            'es': 'Almacén existente',
            'en': 'Existing storehouse'
        },
        'EGST': {
            'es': 'Error obteniendo el tipo de almacenamiento',
            'en': 'Error getting the storage type'
        },
        'EGSH': {
            'es': 'Error obteniendo el almacén',
            'en': 'Error getting the storehouse'
        },
        'EUST': {
            'es': 'Error al editar el tipo de almacenamiento',
            'en': 'Error editing storage type'
        },
        'EUSH': {
            'es': 'Error editando el almacén',
            'en': 'Error editing the storehouse'
        },
        'SUST': {
            'es': 'Tipo de almacenamiento actualizado',
            'en': 'Storage type updated'
        },
        'SUSH': {
            'es': 'Almacén actualizado',
            'en': 'Storehouse updated'
        },
        'UELF': {
            'es': 'Error cargando archivos',
            'en': 'Error uploading files'
        },
        'EGAG': {
            'es': 'Error obteniendo las agencias',
            'en': 'Error getting agencies'
        },
        'ECAG': {
            'es': 'Error al crear agencia',
            'en': 'Error creating agency'
        },
        'SCAG': {
            'es': 'Agencia creada',
            'en': 'Agency created'
        },
        'EUPAG': {
            'es': 'Error actualizando agencia',
            'en': 'Error updating agency'
        },
        'SUPAG': {
            'es': 'Agencia actualizada',
            'en': 'Agency updated'
        },
        'EEXAG': {
            'es': 'Nombre de agencia ya existe',
            'en': 'Agency name already exists'
        },
        'EMONF': {
            'es': 'Módulo no encontrado',
            'en': 'Module not found'
        },
        'EUPMO': {
            'es': 'Error al actualizar módulo',
            'en': 'Error updating module'
        },
        'SUPMO': {
            'es': 'Módulo actualizado',
            'en': 'Module updated'
        },
        'EAPTP': {
            'es': 'Error aplicando embalaje',
            'en': 'Error applying packaging'
        },
        'SAPTP': {
            'es': 'Embalaje aplicado con éxito',
            'en': 'Packaging applied'
        },
        'EASTU': {
            'es': 'Error al asignar servicio al usuario',
            'en': 'Error assigning service to the user'
        },
        'SASTU': {
            'es': 'Servicio agregado al usuario',
            'en': 'Service added to the user'
        },
        'EASTA': {
            'es': 'Error al asignar servicio a agencia',
            'en': 'Error assigning service to agency'
        },
        'SASTA': {
            'es': 'Servicio agregado a la agencia',
            'en': 'Service added to agency'
        },
        'EESOA': {
            'es': 'Error editando servicio de agencia',
            'en': 'Error editing agency service'
        },
        'DXMWR': {
            'es': 'Documente existente en registro WR',
            'en': 'Exist document Registering WR Package'
        },
        'FCPR': {
            'es': 'Faltan contenidos por registrar',
            'en': 'Still missing content to register'
        },
        'EWR': {
            'es': 'Error registrando WR',
            'en': 'Error register WR'
        },
        'EGPACR': {
            'es': 'Error obteniendo las credenciales de pago',
            'en': 'Error getting payment credentials'
        },
        'EUPACR': {
            'es': 'Error actualizando las credenciales de pago',
            'en': 'Error updating payment credentials'
        },
        'SUPACR': {
            'es': 'Credenciales de pago actualizadas',
            'en': 'Updated payment credentials'
        },
        'EGDV': {
            'es': 'Error al obtener los valores',
            'en': 'Error in obtaining the values'
        },
        'ECHDV': {
            'es': 'El país tiene el valor en dolares',
            'en': 'The country has the value in dollars'
        },
        'EUDV': {
            'es': 'No se pudo modificar el valor',
            'en': 'The value could not be modified'
        },
        'SUDV': {
            'es': 'Valor del dólar modificado',
            'en': 'Value of dollar modified'
        },
        'ECMG': {
            'en': 'Error creating master guide',
            'es': 'Error creando guia master'
        },
        'SCMG': {
            'en': 'Success creating master guide',
            'es': 'Éxito creando guía master'
        },
        'EGMG': {
            'en': 'Error getting master guide',
            'es': 'Error obteniendo guía master'
        },
        'SGATAWB': {
            'en': 'Success Guide Added To AWB',
            'es': 'Éxito añadiendo guía a AWB '
        },
        'EGATAWB': {
            'en': 'Error Guide Added To AWB',
            'es': 'Error añadiendo guía a AWB'
        },
        'SCAWB': {
            'en': 'Success Consolidate AWB',
            'es': 'Éxito consolidando AWB'
        },
        'ECAWB': {
            'en': 'Error Consolidate AWB',
            'es': 'Error consolidando AWB'
        },
        'SRGAWB': {
            'en': 'Success Removed Guide From AWB',
            'es': 'Éxito removiendo guía de AWB'
        },
        'ERGAWB': {
            'en': 'Error Removed Guide From AWB',
            'es': 'Error removiendo guía de AWB'
        },
        'EXAWB': {
            'en': 'Error Existent master guide',
            'es': 'Error guía master existente'
        },
        'SAPPRG': {
            'en': 'Success in applying readjustment guide',
            'es': 'éxito al aplicar guía de reajuste'
        },
        'EAPPRG': {
            'en': 'Error apply  guide readjustment',
            'es': 'Error de aplicación del reajuste de la guía'
        },
        'ECCAWB': {
            'en': 'Error creating carrier AWB',
            'es': 'Error al crear el actor AWB'
        },
        'SCCAWB': {
            'en': 'Success creating carrier AWB',
            'es': 'Éxito en la creación del actor AWB'
        },
        'EGCAWB': {
            'en': 'Error getting Carrier AWB',
            'es': 'Error al obtener los actores AWB'
        },
        'EXDTC': {
            'en': 'Error Existent DutyTax Code',
            'es': 'Error Código de posición arancelaria existente'
        },
        'EUCAWB': {
            'en': 'Error updating carrier AWB',
            'es': 'Error al modificar el actores AWB'
        },
        'SUCAWB': {
            'en': 'Success updating Carrier AWB',
            'es': 'Éxito al modificar el actor AWB'
        },
        'ECCOAWB': {
            'en': 'Error Existent Consolidated',
            'es': 'Error Consolidado Existente'
        },
        'EEXCO': {
            'en': 'Error creating Consolidated',
            'es': 'Error Creando Consolidado'
        },
        'SCCOAWB': {
            'en': 'Success creating Consolidated',
            'es': 'Éxito Crenado Consolidado'
        },
        'EGCOAWB': {
            'en': 'Error getting Consolidated',
            'es': 'Error Obteniendo Consolidado'
        },
        'SGATCON': {
            'en': 'Success Guide Added To Consolidated',
            'es': 'Éxito Agregando a Consolidado'
        },
        'EGATCON': {
            'en': 'Error Guide Added To Consolidated',
            'es': 'Error Agregando Guia a Consolidado'
        },
        'SGRFCON': {
            'en': 'Success Guide Removed From Consolidated',
            'es': 'Éxito Removiendo Guia del Consolidado'
        },
        'EGRFCON': {
            'en': 'Error Guide Removed From Consolidated',
            'es': 'Error Removiendo guia del Consolidado'
        },
        'SCOCOAWB': {
            'en': 'Success Closing Consolidated',
            'es': 'Éxito Cerrando Consolidado'
        },
        'ECOCOAWB': {
            'en': 'Error Closing Consolidated',
            'es': 'Error Cerrando Consolidado'
        },
        'SCGUST': {
            'en': 'Success creating Guide Status',
            'es': 'Éxito al crear el estado de la guía'
        },
        'ECGUST': {
            'en': 'Error creating Guide Status',
            'es': 'Error al crear el estado de la guía'
        },
        'EEGUST': {
            'en': 'Error the status of the guide already exists',
            'es': 'Error el estado de la guía ya existe'
        },
        'SUGUST': {
            'en': 'Success updating Guide Status',
            'es': 'Éxito modificando el estado de guía'
        },
        'EUGUST': {
            'en': 'Error updating Guide Status',
            'es': 'Error al  modificar el estado de guía'
        },
        'SPUGFA': {
            'en': 'Success Pick up Guide From Agency',
            'es': 'éxito al registrar la guía como recolectada'
        },
        'EPUGFA': {
            'en': 'Error Pick up Guide From Agency',
            'es': 'Error al registrar la guía como recolectada'
        },
        'EGARIM': {
            'en': 'Error guide already registered in master',
            'es': 'Error la guía ya fue registrada'
        },
        'ERGIM': {
            'en': 'Error register guide in master',
            'es': 'Error al registrar la guía en master'
        },
        'SRGIM': {
            'en': 'Success Register Guide in Master',
            'es': 'éxito al registrar la guía en master'
        },
        'ERGID': {
            'en': 'Error register guide in destiny',
            'es': 'Error al registar la guía en país destino'
        },
        'SRGID': {
            'en': 'Success register guide in destiny',
            'es': 'éxito al registrar la guía en país destino'
        },
        'SAGUST': {
            'en': 'Success apply guide status',
            'es': 'éxito al aplicar estado a la guía'
        },
        'EAGUST': {
            'en': 'Error apply guide status',
            'es': 'Error al aplicar estado a la guía'
        },
        'EGARIDC': {
            'en': 'Error guide already registered in destiny country',
            'es': 'Error guía ya se encuentra en registrada'
        },
        'EUCAGS': {
            'en': 'Error user cant apply guide status',
            'es': 'Error usuario no puede aplicar el estado a la guía'
        },
        'EAGC': {
            'en': 'Error to Add Guide to Consolidated',
            'es': 'Error para Añadir Guía al Consolidado '
        },
        'SCUA': {
            'en': 'Succesfully Create user agency',
            'es': 'Usuario creado con éxito'
        },
        'SCS': {
            'en': 'Succesfully Create Scale',
            'es': 'Escala Creada Con éxito'
        },
        'SRS': {
            'es': 'Escala Eliminada!',
            'en': 'Scale Deleted!'
        },
        'GSD': {
            'es': 'Guía Simple Eliminada!',
            'en': 'Simple Guide Deleted!'
        },
        'ECMR': {
            'en': 'Error to Create Required Measures',
            'es': 'Error Al Crear Medidas Requeridas'
        },
        'SCMR': {
            'en': 'Succesfully Create Required Measures',
            'es': 'Medidas Requeridas Creadas Con éxito'
        },
        'EUG': {
            'en': 'Error Updating Guides',
            'es': 'Error Actualizando Guías'
        },
        'SUG': {
            'en': 'Success Updating Guides',
            'es': 'Éxito Actualizando Guías'
        },
        'EARFC': {
            'en': 'Error Registering Required Fields of Carrier',
            'es': 'Error al Registrar Campos Requeridos de Transportadora'
        },
        'SARFC': {
            'en': 'Successful Registration of Required Fields of Carrier',
            'es': 'Éxito al Registrar Campos Requeridos de Transportadora'
        },
        'EUV': {
            'en': 'Error Updating Values',
            'es': 'Error Actualizando Valores'
        },
        'NS': {
            'en': 'There are not selected elements',
            'es': 'No hay elementos seleccionados'
        },
        'NOTMD': {
            'en': 'The end date should be greater than the start date',
            'es': 'La fecha final debe ser mayor que la fecha inicial'
        },
        'SP': {
            'en': 'Payment approved successfully',
            'es': 'Pago aprobado exitosamente'
        },
        'EP': {
            'en': 'Error approving payment',
            'es': 'Error al aprobar pago'
        },
        'EWI': {
            'en': 'Invalid weight',
            'es': 'Peso invalido',
        },
        'NFAC': {
            'en': 'Could not connect to company',
            'es': 'No se pudo conectar con la empresa',
        },
        'EGNAE': {
            'en': 'The Guide Name Already Exists',
            'es': 'La guia a cargar ya existe en el sistema',
        },
        'EEES': {
            'en': 'The service is already assigned to enterprise',
            'es': 'El servicio ya esta asignado a la empresa',
        },
        'EEAS': {
            'en': 'The service is already assigned to agency',
            'es': 'El servicio ya esta asignadoa a la agencia',
        },
        'SASTE': {
            'es': 'Servicio agregado a la empresa',
            'en': 'Service added to enterprise'
        },
        'EESOE': {
            'es': 'Error editando servicio de empresa',
            'en': 'Error editing enterprise service'
        },
        'SESOA': {
            'es': 'Exito al editar servicio',
            'en': 'Sevice Edited '
        },
        'NG': {
            'es': 'No hay informacion por mostrar',
            'en': 'There are not information for show'
        },
        'SCG': {
            'es': 'Guia cancelada correctamente',
            'en': 'Success canceling guide',
        },
        'ECAC': {
            'es': 'No se puede conectar al sistema de la transportadora',
            'en': 'Unable to connect to carrier system'
        },
        'NRTM': {
            'es': 'No hay un método de seguimiento registrado para esta transportadora',
            'en': 'There is no registered tracking method for this carrier'
        },
        'CBSM': {
            'es': 'No se pudo enviar el correo a el usuario',
            'en': 'Could not be send email'
        },
        'NFO': {
            'es': 'Operador no encontrado',
            'en': 'Not Found Operator'
        },
        'ECL': {
            'es': 'Error creando la direccion',
            'en': 'Error creating location'
        },
        'TGHARATTC': {
            'es': 'La Guía ya fue agregada al consolidado',
            'en': 'The Guide has already been added to the consolidated'
        },
        'EGNC': {
            'es': 'El país de la guía no corresponde con el de consolidado',
            'en': 'The country of the guide does not correspond to that of consolidated'
        },
        'EINATETG': {
            'es': 'No está autorizado para editar esta guía',
            'en': 'Is not authorized to edit this guide'
        },
        'ENEBS': {
            'es': 'Esta guía no se puede editar por el estado en el que se encuentra',
            'en': 'This guide cannot be edited by the status in which it is'
        },
        'EPG': {
            'es': 'Pago con Ebanx generado exitosamente',
            'en': 'Payment with Ebanx successfully generated'
        },
        'EEPG': {
            'es': 'Error generando pagar con Ebanx',
            'en': 'Error generating pay with Ebanx'
        },
        'SECTWR': {
            'es': 'Se edito el tracking correctamente',
            'en': 'Success tracking edited'
        },
        'EECTWR': {
            'es': 'Error editando tracking',
            'en': 'Error editing tracking '
        },
        'SLF': {
            'es': 'Archivos subidos correctamente',
            'en': 'Succesfully Load File'
        },
        'SECTNR': {
            'es': 'Se editaron las notas correctamente',
            'en': 'Success tracking edited'
        },
        'EECTNR': {
            'es': 'Error editando notas',
            'en': 'Error editing tracking '
        },
        'RCI': {
            'es': 'Por favor apruebe el paquete',
            'en': 'Please approve the package'
        },
        'NFP': {
            'es': 'Paquete no encontrado',
            'en': 'Not Found Package'
        },
        'NDC': {
            'es': 'No se encontro contenido declarado',
            'en': 'Not Found Declared Content'
        },
        'NCS': {
            'es': 'No se encontraron servicios',
            'en': 'Not Found Service'
        },
        'EGDR': {
            'es': 'Error obtenierndo reportes',
            'en': 'Error getting report'
        },
        'SGE': {
            'es': 'Guía editada Exitosamente',
            'en': 'Successfully edited guide'
        },
        'ORS': {
            'es': 'Operario registrado con éxito',
            'en': 'Operator registered succesfuly'
        },
        'EGIC': {
            'es': 'Error guide is canceled',
            'en': 'Error la guía está cancelada'
        },
        'EGGUST': {
            'en': 'Error getting Guide Status',
            'es': 'Error obteniendo estatus de guía'
        },
        'ECNF': {
            'en': 'Error Consolidated Not Found',
            'es': 'Error consolidado no encontrado'
        },
        'SCWR': {
            'en': 'Succesfully Closed Wr',
            'es': 'WR Cerrado con éxito',
        },
        'EGWR': {
            'en': 'Error Getting WR',
            'es': 'Error obteniendo WR',
        },
        'SRTRW': {
            'en': 'Success Register Tracking',
            'es': 'Tracking Registrado Exitosamente',
        },
        'EAETWR': {
            'en': 'Already Exist Tracking Warehouse',
            'es': 'El tracking ya está registrado',
        },
        'EVU': {
            'en': 'User already validate',
            'es': 'El usario ya está validado',
        },
        'SUPR': {
            'en': 'Success updating request',
            'es': 'Solicitud actualizada con éxito',
        },
        'MW': {
            'en': 'Maximum number of boxes exceeded per user',
            'es': 'Máxima cantidad de cajas superadas por usuario',
        },
        'GWDR': {
            'en': 'The guide does not have a report',
            'es': 'La guia no tiene un reporte de entrega',
        },
        'GDR': {
            'en': 'The guide already has a report',
            'es': 'La guía ya tiene un reporte de entrega'
        },
        'MAXDPR': {
            'en': 'The total declared value of your items for this service must be less than 2000 USD',
            'es': 'El valor declarado total de tus artículos para este servicio debe ser inferior a 2000 USD',
        },
        'MAXDPC': {
            'en': 'The total declared value of your items for this service must be less than 200 USD',
            'es': 'El valor declarado total de tus artículos para este servicio debe ser inferior a 200 USD',
        },
        'NSAOA': {
            'en': 'There are no services available for the current location or there are no active locations',
            'es': 'No hay servicios disponibles para la ubicación actual o no hay ubicaciones activas'
        },
        'NCL': {
            'en': 'Not Active Location',
            'es': 'Ubicación no activa'
        }
    };

    constructor(private toastr: ToastrService) { }

    private getMessage(language, messageCode) {
        if (!this.messages[messageCode]) {
            return this.messages['invalidCode'][language];
        }
        return this.messages[messageCode][language];
    }

    showErrorMessage(language: String, messageCode: String) {
        const message = this.getMessage(language, messageCode);
        const title = this.getMessage(language, 'errorTitle');
        this.toastr.error(message, title, { closeButton: true });
    }

    showSuccessMessage(language: String, messageCode: String) {
        const message = this.getMessage(language, messageCode);
        const title = this.getMessage(language, 'successTitle');
        this.toastr.success(message, title, { closeButton: true });
    }

    showLongSuccessMessage(language: String, messageCode: String) {
        const message = this.getMessage(language, messageCode);
        const title = this.getMessage(language, 'successTitle');
        this.toastr.success(message, title, { closeButton: true, timeOut: 10000 });
    }

    showInfoMessage(language: String, messageCode: String) {
        const message = this.getMessage(language, messageCode);
        this.toastr.info(message, 'Info', { closeButton: true, timeOut: 10000 });
    }

    showInfoMessageWithParameter(language: String, messageCode: String, parameter) {
        const message = this.getMessage(language, messageCode) + ' ' + parameter;
        this.toastr.info(message, 'Info', { closeButton: true, timeOut: 10000 });
    }

    showWarningMessage(language: String, messageCode: String) {
        const message = this.getMessage(language, messageCode);
        const title = this.getMessage(language, 'warning');
        this.toastr.warning(message, title, { closeButton: true, timeOut: 10000 });
    }

    showGetMessage(language, messageCode) {
        if (!this.messages[messageCode]) {
            return this.messages['invalidCode'][language];
        }
        return this.messages[messageCode][language];
    }
}
